import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Moment from 'react-moment'
import Overlay from './assets/overlay-wheel.png'

interface PostHeaderData {
  heading?: string
  date?: string
  author: {
    name?: string
    content?: string
    image?: any
  }
  authorString?: string
}

const PostHeader = ({
  author: { name, content, image },
  heading,
  date,
  authorString = 'Kirjoittaja',
}: PostHeaderData) => {
  const avatar = image && getImage(image?.localFile)

  const hasBox = content && image && name

  return (
    <div className={`post-header my-10 grid ${!hasBox ? 'grid-cols-1 max-w-6xl mx-auto' : 'lg:grid-cols-2'} gap-10`}>
      <div>
        <p className="mb-2 text-4xl">
          <Moment format="DD.MM.YYYY" date={date} />
        </p>
        <h1 className="text-4xl md:text-7xl break-words mb-2 wordbreak md:leading-[3.5rem]">{heading}</h1>
        {name && (
          <p className="text-4xl">
            {authorString}: {name}
          </p>
        )}
      </div>
      {hasBox ? (
        <div className="bg-primary w-full flex items-center relative overflow-hidden mb-16">
          <div className="p-5 sm:p-10 w-full flex-col sm:flex-row flex flex-shrink-0">
            {avatar && (
              <div className="mb-5 sm:mb-0 sm:mr-10 w-2/4 h-2/4 sm:w-1/3 sm:h-1/3 flex-shrink-0 rounded-full overflow-hidden object-cover">
                <GatsbyImage
                  image={avatar}
                  className="rounded-full aspect-square bg-no-repeat bg-cover bg-center person-image"
                  alt={''}
                />
              </div>
            )}
            <div className="z-10 relative">
              <p className="text-5xl break-words text-white">{name}</p>
              <p className="font-helvetica text-xl break-words text-white max-w-sm">{content}</p>
            </div>
            <img alt="" width="100" height="100" className="absolute -right-1/2 -bottom-96 w-4/5" src={Overlay} />
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default PostHeader
